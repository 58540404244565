
export const disqusConfig  = (fullUrl, title) => {
  const url = fullUrl.endsWith('/') ? fullUrl.substr(0, fullUrl.length - 1) : fullUrl
  const disqusConfig = {
    url: `${url}.html`,
    id: `${url}.html`,  // match existing site: "https://agilitynerd.com/blog/agility/courses/misc/2016-05-pinneri-sigeti-premier.html"
    title: 'AgilityNerd Dog Agility Blog - ' + title,
  }
  return disqusConfig
}
