import './tagslist.scss'

import { Link } from "gatsby"
import React from "react"

class TagsList extends React.Component {
  render() {
    const { tags, selected } = this.props
    return tags ? (
      <ul className="tagslist" aria-label="Tags associated with this article">
        {
          tags.sort((a, b) => a.localeCompare(b)).map(tag => {
            return (
              (tag !== selected) ?
                <li key={tag} className="badge bg-primary">
                  <Link to={`/blog/tags/${tag}/`}>{tag}</Link>
                </li> :
                <li key={tag} className="badge bg-secondary"><span>{tag}</span></li>
            )
          })
        }
      </ul>
    ) : ''
  }
}

export default TagsList
