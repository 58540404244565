import "./breadcrumb.scss"

import { Link } from "gatsby"
import React from "react"

/**
 * Return the path sections before the final element of a URL path
 *
 * @param {*} url
 */
const pathDirname = url => url.split('/').slice(0, -1)


const Breadcrumb = ({ path, disableLast = false }) => {
  const parentElements = pathDirname(path).map(el => el + '/')
  const fullPaths = parentElements.reduce((acc, path, idx) => {
    if (!idx) {
      acc.push({ path: path, label: 'home' })
    } else {
      acc.push({ path: acc[idx - 1].path + path, label: path.replace('/', '') })
    }
    return acc
  }, [])
  return fullPaths.length > 1 ? <nav className="breadcrumb-nav border border-primary border-1 rounded p-2 bg-primary-subtle" arial-label="Breadcrumbs"><ul className='breadcrumb mb-0 ms-2 d-print-none'>
    {fullPaths
      .map((el, idx) => {
        return <li key={el.path} className="breadcrumb-item">
          {idx === fullPaths.length - 1 && disableLast ? <span className="breadcrumb-label">{el.label}</span> :
            <Link to={el.path}
              className="crumb">
              {el.label}
            </Link>}
        </li>
      })}
  </ul></nav> : null
}

export default Breadcrumb
